<template>
    <div class="home">
        <div class="home-title">
            <el-card shadow="always">
                <el-row>
                    <el-col :span="6" style="text-align: left;" class="hidden-md-and-down">
                        <div class="greetings">您好,{{ username }}</div>
                        <div class="weather">今天是：{{ curdate }}</div>
                    </el-col>
                    <el-col :span="6" style="display: flex;justify-content: center;align-items: center;"
                        class="hidden-md-and-down">
                        开店时间：<span v-if="ismerchant == 1 || ismerchant == 6 || ismerchant == 7">{{ addtime }}</span>
                        <span v-else>{{
                            merchants.openmerchant }}</span>
                    </el-col>
                </el-row>
            </el-card>
        </div>
        <el-row :gutter="20" v-if="ismerchant == 1" style="margin-top:20px;">
            <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
                <el-card shadow="never" class="box-card" style="background:#00C9B7;color:#fff">
                    <div class="settleblock">
                        <p style="font-size:20px;margin:10px 0 10px 0"><strong>结算账户</strong></p>
                        <p style="font-size:30px;margin:10px 0 10px 0"><strong>¥ {{ settle ? settle : 0 }}</strong></p>
                        <div style="margin-left:auto;">
                            <el-button size="small" style="background:transparent;color:#fff;"
                                @click="Settle">明细</el-button>
                            <el-button size="small" style="background:transparent;color:#fff"
                                @click="Cashout">提现</el-button>
                        </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
                <el-card shadow="never" class="box-card" style="background:#ff7a00;color:#fff">
                    <div class="settleblock">
                        <p style="font-size:20px;margin:10px 0 10px 0"><strong>充值账户</strong></p>
                        <p style="font-size:30px;margin:10px 0 10px 0"><strong>¥ {{ recharge }}</strong></p>
                        <div style="margin-left:auto;">
                            <el-button size="small" style="background:transparent;color:#fff;"
                                @click="chongzhi">充值</el-button>
                            <el-button size="small" style="background:transparent;color:#fff;"
                                @click="rechargedetail">明细</el-button>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <el-tabs v-model="activeName" v-if="show == 1" v-loading="loading" element-loading-text="处理中..."
            style="margin-top:20px;" @tab-click="handleClick">
            <el-tab-pane label="整体数据" name="first">
                下单时间：<el-date-picker v-model="date" type="daterange" range-separator="到" start-placeholder="开始时间"
                    end-placeholder="结束时间" size="small" />
                <el-button type="primary" @click="search" style="margin-left:20px;" size="small">搜索</el-button>
                <div class="home-card m120">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info ">
                                    <div class="card-info-title"><i class="el-icon-document"></i> 已发货订单总数</div>
                                    <div class="card-info-no">{{ merchants.totalorder }}</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title"><i class="el-icon-coin"></i> 租赁中订单总数</div>
                                    <div class="card-info-no">{{ merchants.renting }}</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title"><i class="el-icon-box"></i> 已买断/已完结订单总数</div>
                                    <div class="card-info-no">{{ merchants.buyout }}</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title"><i class="el-icon-files"></i>总机价(含点)</div>
                                    <div class="card-info-no">{{ merchants.cost }}</div>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top:20px" :gutter="20">
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title"><i class="el-icon-document"></i> 签约价值总和</div>
                                    <div class="card-info-no">{{ merchants.signpricetotal }}</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title"><i class="el-icon-box"></i> 已收租金</div>
                                    <div class="card-info-no">{{ merchants.fund }}</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title"><i class="el-icon-coin"></i> 回款资金</div>
                                    <div class="card-info-no">{{ merchants.huikuan }}</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title"><i class="el-icon-coin"></i> 应收未收租金</div>
                                    <div class="card-info-no">{{ merchants.yingshou }}</div>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top:20px" :gutter="20">
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title"><i class="el-icon-box"></i> 逾期订单数</div>
                                    <div class="card-info-no">{{ merchants.overdue }}</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title"><i class="el-icon-box"></i> 逾期率(逾期订单/已发货订单)</div>
                                    <div class="card-info-no">{{ merchants.yql }}%</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title"><i class="el-icon-coin"></i> 逾期金额</div>
                                    <div class="card-info-no">{{ merchants.yuqi }}</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title"><i class="el-icon-files"></i> 逾期金额率<span
                                            style="font-size:17px;">(已回收资金/签约价值总和)</span></div>
                                    <div class="card-info-no">{{ merchants.overduemoney }}%</div>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top:20px" :gutter="20">
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title"><i class="el-icon-coin"></i>首逾订单数(第2期租金扣款订单数量)</div>
                                    <div class="card-info-no">{{ merchants.twofail }}</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title"><i class="el-icon-coin"></i>首逾率(第二期租金扣款失败的订单/总订单数)
                                    </div>
                                    <div class="card-info-no">{{ merchants.twfai }}%</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title"><i class="el-icon-coin"></i>首逾金额(第2期租金扣款订单的应收未收租金)
                                    </div>
                                    <div class="card-info-no">{{ merchants.failmoney }}</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title"><i class="el-icon-coin"></i>金额首逾率(逻辑:首逾金额/签约价值)</div>
                                    <div class="card-info-no">{{ merchants.moneyfail }}%</div>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top:20px" :gutter="20">
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title"><i class="el-icon-coin"></i>毛利(签约价值-投入设备总机价)</div>
                                    <div class="card-info-no">{{ merchants.maoli }}</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title"><i class="el-icon-document"></i>毛利率(毛利/签约价值)</div>
                                    <div class="card-info-no">{{ merchants.maolil }}%</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title"><i class="el-icon-coin"></i>押金</div>
                                    <div class="card-info-no">{{ merchants.yajin }}</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title"><i class="el-icon-coin"></i>锁机费</div>
                                    <div class="card-info-no">{{ merchants.suoji }}</div>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                </div>
            </el-tab-pane>
            <el-tab-pane label="月份数据" name="second">
                <el-table :data="monthDatas" style="width: 100%;margin-top:20px;" height="700">
                    <el-table-column prop="jinglirun" sortable>
                        <template #header="{}">
                            <div class="custom-header">
                                预-净利润
                                <el-tooltip class="box-item" effect="light"
                                    content="签约价值总和*结算比例+逾期订单押金-总机价-M1以上逾期金额-优惠金-锁机费-审核费" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="huibaolv" sortable>
                        <template #header="{}">
                            <div class="custom-header">
                                预-回报率（年化）
                                <el-tooltip class="box-item" effect="light" content="预-净利润/（签约价值总和/3/1.3）*100"
                                    placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="shilirun" sortable>
                        <template #header="{}">
                            <div class="custom-header">
                                实-净利润
                                <el-tooltip class="box-item" effect="light" content="已收租金*结算比例+逾期订单押金-总机价-锁机费-审核费"
                                    placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="shihuilv" sortable>
                        <template #header="{}">
                            <div class="custom-header">
                                实-回报率（年化）
                                <el-tooltip class="box-item" effect="light" content="实-净利润/（签约价值总和/3/1.3）*100"
                                    placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="month" label="月份" />
                    <el-table-column prop="totalorder" sortable>
                        <template #header="{}">
                            <div class="custom-header">
                                已发货订单总数
                                <el-tooltip class="box-item" effect="light" content="租赁中订单+已买断/已完结订单" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="renting" sortable>
                        <template #header="{}">
                            <div class="custom-header">
                                租赁中订单总数
                                <el-tooltip class="box-item" effect="light" content="待归还+还款逾期中" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="buyout" label="已买断/已完结订单总数" sortable />
                    <el-table-column prop="cost" label="总机价(含点)" sortable />
                    <el-table-column prop="signpricetotal" label="签约价值总和" sortable />
                    <el-table-column prop="fund" label="已收租金" sortable />
                    <el-table-column prop="huikuan" sortable>
                        <template #header="{}">
                            <div class="custom-header">
                                回款资金
                                <el-tooltip class="box-item" effect="light" content="已收租金+租赁中订单的押金" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="yingshou" label="" sortable>
                        <template #header="{}">
                            <div class="custom-header">
                                应收未收租金
                                <el-tooltip class="box-item" effect="light" content="签约价值总和-已收租金-优惠金额-在租订单押金"
                                    placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="overdue" label="逾期订单数" sortable />
                    <el-table-column prop="overduerate" label="" sortable>
                        <template #header="{}">
                            <div class="custom-header">
                                逾期率/%
                                <el-tooltip class="box-item" effect="light" content="逾期订单/已发货订单" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="overdueM0" sortable>
                        <template #header="{}">
                            <div class="custom-header">
                                M0订单数
                                <el-tooltip class="box-item" effect="light" content="30天以内逾期的订单" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="overdueM1" sortable>
                        <template #header="{}">
                            <div class="custom-header">
                                M1以上订单数
                                <el-tooltip class="box-item" effect="light" content="30天以上逾期的订单" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="badMoney" sortable>
                        <template #header="{}">
                            <div class="custom-header">
                                金额坏账率
                                <el-tooltip class="box-item" effect="light" content="M1以上逾期金额/签约价值总和" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="mm0" sortable>
                        <template #header="{}">
                            <div class="custom-header">
                                M0逾期金额
                                <el-tooltip class="box-item" effect="light" content="M0订单的签约价值-已收租金-押金" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="mm1" label="" sortable>
                        <template #header="{}">
                            <div class="custom-header">
                                M1以上逾期金额
                                <el-tooltip class="box-item" effect="light" content="M1订单的签约价值-已收租金-押金" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="yuqi" sortable>
                        <template #header="{}">
                            <div class="custom-header">
                                逾期金额
                                <el-tooltip class="box-item" effect="light" content="逾期订单的签约价值-已收租金-押金" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="yuqirate" sortable>
                        <template #header="{}">
                            <div class="custom-header">
                                逾期金额率/%
                                <el-tooltip class="box-item" effect="light" content="逾期金额/签约价值总和" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="first" sortable>
                        <template #header="{}">
                            <div class="custom-header">
                                首逾订单数
                                <el-tooltip class="box-item" effect="light" content="第2期租金扣款失败订单" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="fst" sortable>
                        <template #header="{}">
                            <div class="custom-header">
                                首逾率/%
                                <el-tooltip class="box-item" effect="light" content="首逾订单/已发货订单" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="firstmoney" sortable>
                        <template #header="{}">
                            <div class="custom-header">
                                首逾金额
                                <el-tooltip class="box-item" effect="light" content="首逾订单的签约价值-已收租金-押金" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="firstmoneyrate" sortable>
                        <template #header="{}">
                            <div class="custom-header">
                                首逾金额率/%
                                <el-tooltip class="box-item" effect="light" content="首逾金额/签约价值总和" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="fmrate" sortable>
                        <template #header="{}">
                            <div class="custom-header">
                                逾期/首逾金额率
                                <el-tooltip class="box-item" effect="light" content="逾期金额/首逾金额" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="maoli" sortable>
                        <template #header="{}">
                            <div class="custom-header">
                                毛利
                                <el-tooltip class="box-item" effect="light" content="签约价值总和-总机价-优惠金" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="maolirate" sortable>
                        <template #header="{}">
                            <div class="custom-header">
                                毛利率/%
                                <el-tooltip class="box-item" effect="light" content="毛利/签约价值总和" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deposit" label="押金" sortable />
                    <el-table-column prop="deposit2" label="逾期订单押金" sortable />
                    <el-table-column prop="favorable" sortable>
                        <template #header="{}">
                            <div class="custom-header">
                                优惠金
                                <el-tooltip class="box-item" effect="light" content="已买断已完结订单的签约价值-已收租金"
                                    placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="favorablerate" sortable>
                        <template #header="{}">
                            <div class="custom-header">
                                优惠金率
                                <el-tooltip class="box-item" effect="light" content="优惠金/（签约价值总和-总机价）" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="suoji" label="锁机费" sortable />
                    <el-table-column prop="shenhe" label="审核费" sortable />
                </el-table>
            </el-tab-pane>
            <!-- <el-tab-pane label="每日数据" name="five">
        时间：<el-date-picker v-model="every_day" type="daterange" range-separator="到" start-placeholder="开始时间"
          end-placeholder="结束时间" size="small" />
        <el-button type="primary" @click="search" style="margin-left:20px;" size="small">搜索</el-button>
        <el-table :data="everyday" height="700" style="width: 100%;margin-top:20px;">
          <el-table-column prop="time" label="日期" sortable />
          <el-table-column prop="total" label="订单数" sortable />
          <el-table-column prop="buyoutprice" label="签约价" sortable />
          <el-table-column prop="margin" label="总机价" sortable />
          <el-table-column prop="average" label="平均机价" sortable />
          <el-table-column prop="profit" label="毛利" sortable />
        </el-table>
        <el-pagination background :page-sizes="[200, 500]" layout="total, sizes,  prev, pager, next, jumper"
          v-model:page-size="everypageSize" @size-change="everySizeChange" @current-change="everyCurrentChange"
          :total="everytotal" />
      </el-tab-pane> -->
            <el-tab-pane label="资金投入概况" name="ten">
                <el-card style="max-width: 480px">
                    <p>您目前投入本金为{{ touru }}元</p>
                    <p>转化为月度占用资金，约为{{ leiji }}元</p>
                    <p>转化为年度占用资金，约为{{ yuedu }}元</p>
                </el-card>
                <el-table :data="Investment['res']" border style="margin-top:20px;">
                    <el-table-column prop="month" label="月份" align="center" />
                    <el-table-column prop="principal" label="累计本金" align="center" />
                    <el-table-column prop="Investment" label="充值" align="center" />
                    <el-table-column prop="CashOut" label="提现" align="center" />
                    <el-table-column prop="occupy" label="月度占用资金" align="center" />
                </el-table>
            </el-tab-pane>
        </el-tabs>
    </div>
    <el-dialog v-model="merchant" title="选择商户" width="12%">
        <el-select v-model="vv" class="m-2" clearable placeholder="选择商户">
            <el-option v-for="item in allmerchant" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="merchant = false">取消</el-button>
                <el-button type="primary" @click="savemerchant">
                    确认
                </el-button>
            </span>
        </template>
    </el-dialog>
    <el-dialog v-model="dialogVisible" title="充值" width="30%" :before-close="handleClose">
        <el-row style="background-color: #0082fc;border-radius: 10px;color:white;">
            <el-col :span="24" style="padding: 15px 26px;">账号余额(元)</el-col>
            <el-col :span="24" style="padding: 15px 26px;">{{ recharge }}</el-col>
        </el-row>
        <el-collapse v-model="activeNames" style="margin-top:14px;" @change="handleChange">
            <el-collapse-item title="支付宝充值" name="1">
                <el-row style="margin-top:25px">
                    <el-col :span="3">
                        收款码
                    </el-col>
                    <el-col :span="21">
                        <el-image :zoom-rate="1.2"
                            :preview-src-list="['https://xuanzj.xianniuzu.com/wx_image/shoukuanma.jpeg']"
                            :initial-index="4" fit="cover" src="https://xuanzj.xianniuzu.com/wx_image/shoukuanma.jpeg"
                            style="width:100px;height:100px;"></el-image>
                    </el-col>
                </el-row>
                <el-row style="margin-top:25px">
                    <el-col :span="24">
                        企业对公支付宝账号：chenxuanzhan@dingtalk.com
                    </el-col>
                </el-row>
                <el-row style="margin:20px 0">
                    <el-col :span="24">充值金额</el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-input-number v-model="alirecharge" :min="0" :precision="2" style="width:100%;"
                            @change="handleChange" />
                    </el-col>
                </el-row>
            </el-collapse-item>
            <el-alert title="转账时请备注公司名称" type="warning" effect="dark" :closable=false :show-icon=true center
                style="border-radius: 10px;margin: 10px 0;color: red;background-color: white;">
            </el-alert>
            <el-collapse-item title="银行卡closable" name="2">
                <el-row>
                    <el-col :span="24">企业名称：深圳市炫垚科技有限公司</el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">开户行：深圳福田银座村镇银行观澜支行</el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">账号：651028239500015</el-col>
                </el-row>
                <el-row style="margin:20px 0">
                    <el-col :span="24">银行卡转账金额</el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-input-number v-model="bankrecharge" :min="0" :precision="2" style="width:100%;"
                            @change="handleChange" />
                    </el-col>
                </el-row>
            </el-collapse-item>
        </el-collapse>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="saverecharge">提交审核</el-button>
                <el-button @click="dialogVisible = false">取消</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
import { ref } from "vue"
//import {VueEcharts} from 'vue3-echarts'
// import {revenueAnalysis,overview} from '@/api/serviceApi'
import { ElMessageBox } from "element-plus";
import constant from "@/constant"
import Cookies from 'js-cookie'
export default {

    data() {
        return {
            show: "",
            everypage: ref(1),
            everypageSize: ref(100),
            everytotal: "",
            everyday: [],
            every_day: "",
            yuedu: ref(''),
            leiji: ref(''),
            touru: ref(''),
            Investment: ref({}),
            month: [],
            tab_name: ref(''),
            date: ref(""),
            activeName: ref('first'),
            loading: ref(false),
            addtime: "",
            recharge: "",
            alirecharge: ref(0),
            bankrecharge: ref(0),
            dialogVisible: ref(false),
            username: '',
            expired: 0,
            vv: ref(''),
            abouttoexpired: 0,
            on: 0,
            info: [],
            merchants: [],
            ismerchant: '',
            mid: '',
            settle: '',
            cashout: '',
            allmerchant: [],
            merchant: ref(false),
        }
    },
    methods: {
        everySizeChange(val) {
            this.everypage = 1
            this.everypageSize = val
            this.everydaydata()
        },
        everyCurrentChange(val) {
            console.log(val)
            this.everypage = val
            this.everydaydata()
        },
        getFundProfile() {
            this.loading = true
            this.axios.get(constant.getFundProfile, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    merchant: this.mid
                }
            }).then((response) => {
                console.log(response.data)
                this.Investment = response.data
                this.touru = response.data.result.touru
                this.leiji = response.data.result.leiji
                this.yuedu = response.data.result.yuedu
                this.loading = false
            })
        },
        everydaydata() {
            this.loading = true
            this.axios.get(constant.geteveryday, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    date: this.every_day,
                    pageSize: this.everypageSize,
                    page: this.everypage,
                    merchant: this.mid
                }
            }).then((response) => {
                console.log(response.data)
                this.everyday = response.data.result
                this.everytotal = response.data.total * 1
                this.loading = false
            })
        },
        getMonthData() {
            this.loading = true
            this.axios.get(constant.getMonthData, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    merchant: this.mid,
                    store: this.ss,
                    ywy: this.yy,
                    city: this.aa
                }
            }).then((response) => {
                console.log(response.data)
                this.monthDatas = response.data
                this.loading = false
            })
        },
        getdata() {
            this.loading = true
            this.axios.get(constant.getdata, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    merchant: this.mid,
                    month: this.date,
                }
            }).then((response) => {
                this.merchants = response.data
                this.loading = false
            })
        },
        search() {
            if (this.tab_name == 'first') {
                this.getdata()
            }
            if (this.tab_name == 'three') {
                this.getstores()
            }
            if (this.tab_name == 'four') {
                this.getywy()
            }
            if (this.tab_name == 'five') {
                this.everydaydata()
            }
            if (this.tab_name == 'six') {
                this.overduedatas()
            }
            if (this.tab_name == 'eight') {
                this.getCityData()
            }
        },
        handleClick(tab) {
            console.log(tab)
            this.tab_name = tab.props.name
            this.vv = ''
            this.yy = ''
            this.ss = ''
            this.aa = ''
            this.merchantname = ''
            this.storename = ''
            this.ywyname = ''
            this.areaname = ''
            if (tab.props.name == 'first') {
                console.log(this.vv)
                this.activeIndex = ''
                this.date = ''
                this.getdata()
            }
            if (tab.props.name == "second") {
                this.getMonthData()
            }
            if (tab.props.name == 'three') {
                this.tableData = []
                this.getstores()
            }
            if (tab.props.name == 'four') {
                this.date = ''
                this.getywy()
            }
            if (tab.props.name == 'five') {
                this.everydaydata()
            }
            if (tab.props.name == 'six') {
                this.overduedatas()
            }
            if (tab.props.name == 'eight') {
                this.getCity()
                this.getCityData()
            }
            if (tab.props.name == 'nine') {
                this.dayMonthData()
            }
            if (tab.props.name == 'ten') {
                this.getFundProfile()
            }
        },
        rechargedetail() {
            this.$router.push({ path: '/recharge' });
        },
        saverecharge() {
            var title = ""
            if (this.alirecharge != 0) {
                title += "支付宝充值" + this.alirecharge + "元";
            }

            if (this.bankrecharge != 0) {
                title += "银行卡充值" + this.bankrecharge + "元";
            }
            if (title == '') {
                this.$message.error("充值金额不能为0！");
                return false
            }
            ElMessageBox.confirm(
                "确认" + title + "?",
                '提示',
                {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            ).then(() => {
                this.axios.get(constant.savenumber, {
                    headers: {
                        "content-type": "application/json"
                    },
                    params: {
                        alirecharge: this.alirecharge,
                        bankrecharge: this.bankrecharge,
                        mid: this.mid,
                    }
                }).then((response) => {
                    console.log(response)
                    if (response.data.code == 200) {
                        this.$message.success("提交成功")
                        this.dialogVisible = false
                    } else {
                        this.$message.error("提交失败")
                    }
                })
            })

        },
        alipay() {
            console.log(this.num, this.mid)
            this.axios.get(constant.goalipay, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    total_amount: this.num,
                    mid: this.mid,
                }
            }).then((response) => {
                console.log(response.data)
            })
        },
        handleChange(value) {
            console.log(value)
        },
        chongzhi() {
            this.dialogVisible = true
        },
        Settle() {
            this.$router.push({ path: '/settle' });
        },
        Cashout() {
            this.$router.push({ path: '/cashout' });
        },
        GetStatisticInfo() {
            this.axios.get(constant.statisticinfo, {
                headers: {
                    "Content-Type": "application/json",
                }
            }).then((response) => {
                console.log(response.data);
                this.info = response.data;
            })
        },
        GetSettleNCashout() {
            this.axios.get(constant.sncinfo, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    mid: this.mid,
                }
            }).then((response) => {
                console.log(response.data);
                //  this.settle = response.data.settle;
                this.cashout = response.data.cashout;
            });
        }
    },

    created: function () {
        this.ismerchant = Cookies.get("IsMerchant");
        this.mid = Cookies.get("mid");
        this.show = Cookies.get('show')
        console.log(this.show)
        this.GetStatisticInfo();
        this.GetSettleNCashout();
        var dd = new Date();
        var year = dd.getFullYear();
        var month = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
        var day = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
        this.curdate = year + "-" + month + "-" + day;
        const username = Cookies.get("name");
        this.username = username;
        this.tab_name = this.activeName
        this.axios.get(constant.recharge, {
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                mid: this.mid
            }
        }).then((response) => {
            this.recharge = response.data.recharge
            this.settle = response.data.jiesuan
            this.addtime = response.data.addtime
        })
        this.axios.get(constant.getdata, {
            headers: {
                "content-type": "application/json"
            },
            params: {
                merchant: this.mid,
                month: this.date,
            }
        }).then((response) => {
            this.merchants = response.data
        })
    }
}
</script>
<style>
.tip {
    border-radius: 100%;
    border: 1px solid;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: 15px;
    height: 15px;
}

.settleblock {
    padding: 0 20px 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.is-always-shadow2 {
    height: 164.5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentblock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.card-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-info-title {
    font-weight: 200;
    font-size: 20px;
}

.card-info-no {
    padding: 5px;
    font-size: 36px;
    font-weight: 300;
}

.card-info-date {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.info-block {
    margin-top: 20px;

}

.home .home-title .title-msg {
    font-size: 20px;
    padding-bottom: 2rem;
}

.home .home-title .greetings {
    font-size: 18px;
    line-height: 50px;
    letter-spacing: .1em;
}

.home .home-title .weather {
    font-size: 14px;
    color: #999;
    line-height: 24px;
    letter-spacing: .1em;
}

.home .home-title .item {
    color: #808695;
    text-align: center;
    padding: 0.5em;
}

.home .home-title .item-text {
    font-weight: 700;
    font-size: 20px;
    color: #000000;
}

.home .home-card .card-text {
    font-size: 12px;
    font-weight: 400;
    color: #494b52;
    letter-spacing: .1em;
    padding-top: 0.4em;
}

.home .home-chart .satisfaction {
    padding-top: 0.2em;
    text-align: center;
}

.home .home-chart .satisfaction-number {
    font-weight: 700;
    font-size: 30px;
    color: #515a6e;
    line-height: 120px;
    text-align: center;
}

.home .home-chart .satisfaction-image {
    width: 60px;
    height: 60px;
    text-align: center;
}

.home .home-chart .satisfaction-msg {
    line-height: 35px;
    color: #808695;
    font-size: 14px;
    text-align: center;
}

.home .home-chart .satisfaction-ratio {
    line-height: 120px;
    color: #36c17b;
    font-size: 18px;
    text-align: center;
}

.home .home-chart .sale-item {
    border-radius: 0.2em;
    line-height: 3.2em;
    text-align: center;
    margin: 1em 0;
}

.home .home-chart .sale-item .item-image {
    top: 0.6em;
}

.home .home-chart .sale-item .item-num {
    height: 2.4em;
    line-height: 2.4em;
    color: white;
    font-weight: bold;
    font-size: 20px;
    text-align: right;
    padding-right: 1em;
}

.home {
    padding-bottom: 4em;
}

.content {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

}

/**
 内边距
 */
.p4em {
    padding-right: 0.4em;
}

/**
 外边距
 */
.m120 {
    margin-top: 1.2em;
}

.mb120 {
    margin-bottom: 1.2em;
}

/**
 颜色
 */
.c-red {
    color: red;
}

.c-coral {
    color: coral;
}

.c-brown {
    color: brown;
}

.c-cyan {
    color: cyan;
}

.c-forestgreen {
    color: forestgreen;
    font-weight: bold;
}

.c-blue {
    color: #1C86EE
}

/**
 数字效果
 */
.num-effect {
    color: #494b52;
    font-size: 1.5em;
    font-weight: bold;
}

.f-weight {
    font-weight: bold;
}

.div-right {
    float: right;
}

.icon {
    width: 30px;
    height: 30px;
    right: 0.2em;
}
</style>
